import {aliases,mdi} from 'vuetify/iconsets/mdi-svg'
import {mdiChevronDown,mdiFilterVariant} from '@mdi/js'
export const enabled = true
export const isDev = false
export function iconsConfiguration() {

  return {
    defaultSet: 'mdi',
    aliases: {
      ...aliases,
      dropdown: mdiChevronDown,
filter: mdiFilterVariant
    },

    sets: { mdi }
  }
}

