// A collection of helper functions.
export function useHelpers() {
    const isShowingMobileMenu = useState<boolean>("isShowingMobileMenu", () => false);

    function toggleMobileMenu(state: boolean | undefined = undefined) {
        state === undefined
            ? (isShowingMobileMenu.value = !isShowingMobileMenu.value)
            : (isShowingMobileMenu.value = state);
    }

    // Formats the given variation array by replacing all dashes and spaces in the name and value properties with underscores.
    function formatVariationArrays(arr: any[]): any[] {
        return arr.map((a) => {
            // replace all dashes and spaces with underscores
            return {
                name: a.name.replace(/[-\s]/g, ""),
                value: a.value.replace(/[-\s]/g, ""),
            };
        });
    }

    // Determines if two arrays of variations are equal
    function arraysEqual(a1: any[], a2: any[]): boolean {
        const a1Formatted = formatVariationArrays(a1);
        const a2Formatted = formatVariationArrays(a2);
        return JSON.stringify(a1Formatted) === JSON.stringify(a2Formatted);
    }

    // Formats an array of variations by converting the name and value properties to lowercase.
    const formatArray = (arr: any[]): any[] => {
        return arr.map((v) => {
            let name = v.name.toLowerCase();
            name = name.startsWith("pa_") ? name.replace("pa_", "") : name;
            const value = v.value.toLowerCase();
            return {name, value};
        });
    };

    // Clear all cookies
    function clearAllCookies(): void {
        const cookies = document.cookie.split(";");
        for (let i = 0; i < cookies.length; i++) {
            const cookie = cookies[i];
            const eqPos = cookie.indexOf("=");
            const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
            document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
        }
    }
    function clearAllLocalStorage(): void {
        localStorage.clear();
    }

    // Replace a query parameter in a URL
    function replaceQueryParam(param: string, newval: string, url: string, part?: string): string {
        const urlObj = new URL(url);
        const params = new URLSearchParams(urlObj.search);

        if (params.has(param)) {
            let currentVal = params.get(param) || '';
            if (part) {
                const partRegex = new RegExp(part, 'g');
                currentVal = currentVal.replace(partRegex, newval);
            } else {
                currentVal = newval;
            }
            params.set(param, currentVal);
        } else {
            params.append(param, newval);
        }

        urlObj.search = params.toString();
        return urlObj.toString();
    }

    function toggleBodyClass(className: string): void {
        const body = document.querySelector("body");
        body?.classList.contains(className) ? body.classList.remove(className) : body?.classList.add(className);
    }

    function removeBodyClass(className: string): void {
        const body = document.querySelector("body");
        body?.classList.remove(className);
    }
    const addTrailingSlashToUrl = (url) => {
        if (!url.endsWith("/")) {
            return url + "/";
        }
        return url;
    };

    const getLinks = (xmlString) => {
        const locRegex = /<loc>(.*?)<\/loc>/g;
        const links = [];
        let match;

        while ((match = locRegex.exec(xmlString)) !== null) {
            links.push(match[1]);
        }
        return links;
    };
    const logGQLError = (error: any) => {
        // if (!isDev && !route.query.debug) return;
        const errorMessage = error?.gqlErrors?.[0]?.message;
        if (errorMessage) {
            console.error(errorMessage);
        }
    };
    const getLinksObj = (xmlString) => {
        const sitemapRegex = /<url>([\s\S]*?)<\/url>/g;
        const locRegex = /<loc>(.*?)<\/loc>/;
        const lastmodRegex = /<lastmod>(.*?)<\/lastmod>/;

        const links = [];
        let sitemapMatch;

        while ((sitemapMatch = sitemapRegex.exec(xmlString)) !== null) {
            const sitemapContent = sitemapMatch[1];
            const locMatch = locRegex.exec(sitemapContent);
            const lastmodMatch = lastmodRegex.exec(sitemapContent);

            if (locMatch && lastmodMatch) {
                const linkObject = {
                    loc: addTrailingSlashToUrl(locMatch[1]),
                    lastmod: lastmodMatch[1],
                };
                links.push(linkObject);
            }
        }

        return links;
    };
    return {
        formatArray,
        arraysEqual,
        clearAllCookies,
        replaceQueryParam,
        toggleBodyClass,
        removeBodyClass,
        isShowingMobileMenu,
        toggleMobileMenu,
        getLinks,
        getLinksObj,
        clearAllLocalStorage,
        logGQLError

    };
}
