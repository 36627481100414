import { default as _91_46_46_46slug_93Qv3uKeTLMXMeta } from "/vercel/path0/pages/[...slug].vue?macro=true";
import { default as _404lZJpwxvtooMeta } from "/vercel/path0/pages/404.vue?macro=true";
import { default as cart3AXNnOUus9Meta } from "/vercel/path0/pages/cart.vue?macro=true";
import { default as _91slug_93zr7ltafwtNMeta } from "/vercel/path0/pages/category/[slug].vue?macro=true";
import { default as indexraiq4bUafSMeta } from "/vercel/path0/pages/checkout/index.vue?macro=true";
import { default as _91order_id_934QhPERmQPzMeta } from "/vercel/path0/pages/checkout/order-pay/[order_id].vue?macro=true";
import { default as _91order_id_93RMTRWWWPvIMeta } from "/vercel/path0/pages/checkout/order-received/[order_id].vue?macro=true";
import { default as hfd_45send_45ordersCFa1P1qt22Meta } from "/vercel/path0/pages/hfd-send-orders.vue?macro=true";
import { default as indexe9Brt5DfdhMeta } from "/vercel/path0/pages/index.vue?macro=true";
import { default as index9uLfdLkXniMeta } from "/vercel/path0/pages/my-account/index.vue?macro=true";
import { default as login7OJxvpy2mgMeta } from "/vercel/path0/pages/my-account/login.vue?macro=true";
import { default as lost_45passwordbdY3qOPskeMeta } from "/vercel/path0/pages/my-account/lost-password.vue?macro=true";
import { default as _91_46_46_46product_93SBP4NZMwiSMeta } from "/vercel/path0/pages/product/[...product].vue?macro=true";
import { default as component_45stub5X4Ei38PMgMeta } from "/vercel/path0/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stub5X4Ei38PMg } from "/vercel/path0/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "slug",
    path: "/:slug(.*)*",
    component: () => import("/vercel/path0/pages/[...slug].vue")
  },
  {
    name: "404",
    path: "/404",
    component: () => import("/vercel/path0/pages/404.vue")
  },
  {
    name: "cart",
    path: "/cart",
    component: () => import("/vercel/path0/pages/cart.vue")
  },
  {
    name: "category-slug",
    path: "/category/:slug()",
    component: () => import("/vercel/path0/pages/category/[slug].vue")
  },
  {
    name: "checkout",
    path: "/checkout",
    meta: indexraiq4bUafSMeta || {},
    component: () => import("/vercel/path0/pages/checkout/index.vue")
  },
  {
    name: "checkout-order-pay-order_id",
    path: "/checkout/order-pay/:order_id()",
    meta: _91order_id_934QhPERmQPzMeta || {},
    component: () => import("/vercel/path0/pages/checkout/order-pay/[order_id].vue")
  },
  {
    name: "checkout-order-received-order_id",
    path: "/checkout/order-received/:order_id()",
    meta: _91order_id_93RMTRWWWPvIMeta || {},
    component: () => import("/vercel/path0/pages/checkout/order-received/[order_id].vue")
  },
  {
    name: "hfd-send-orders",
    path: "/hfd-send-orders",
    component: () => import("/vercel/path0/pages/hfd-send-orders.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/vercel/path0/pages/index.vue")
  },
  {
    name: "my-account",
    path: "/my-account",
    component: () => import("/vercel/path0/pages/my-account/index.vue")
  },
  {
    name: "my-account-login",
    path: "/my-account/login",
    component: () => import("/vercel/path0/pages/my-account/login.vue")
  },
  {
    name: "my-account-lost-password",
    path: "/my-account/lost-password",
    component: () => import("/vercel/path0/pages/my-account/lost-password.vue")
  },
  {
    name: "product-product",
    path: "/product/:product(.*)*",
    component: () => import("/vercel/path0/pages/product/[...product].vue")
  },
  {
    name: component_45stub5X4Ei38PMgMeta?.name,
    path: "/policy",
    component: component_45stub5X4Ei38PMg
  },
  {
    name: component_45stub5X4Ei38PMgMeta?.name,
    path: "/how-it-works",
    component: component_45stub5X4Ei38PMg
  },
  {
    name: component_45stub5X4Ei38PMgMeta?.name,
    path: "/testimonials",
    component: component_45stub5X4Ei38PMg
  },
  {
    name: component_45stub5X4Ei38PMgMeta?.name,
    path: "/?post_type=product&p=33279",
    component: component_45stub5X4Ei38PMg
  },
  {
    name: component_45stub5X4Ei38PMgMeta?.name,
    path: "/?post_type=product&p=33059",
    component: component_45stub5X4Ei38PMg
  }
]