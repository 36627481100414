export default defineNuxtPlugin((nuxtApp) => {
    if (process.client) {
        window.addEventListener('message', (event) => {
            // Check if the message type is 'REDIRECT'
            if (event.data.type === 'REDIRECT' && event.data.page) {
                // Use the Nuxt router to redirect
                nuxtApp.$router.push(event.data.page);
            }
        });
    }
});