import {useNuxtApp} from '#app';

export default function eventsTracker() {
    const {$fbq} = useNuxtApp();
    const addToCartEvent = (product: { id: Number | String, price: Number, name: String, currency: String }) => {
        const currency = product.currency || 'ILS';
        if (typeof (adscaleAddToCart) == 'function') {
            const adscale = adscaleAddToCart(product.id, product.price, 'ILS');
            console.log('adscale event: ', adscale);
        }
        $fbq('track', 'AddToCart', {
            content_ids: [product.id],
            content_name: product.name,
            content_type: 'product',
            value: product.price,
            currency: currency,
        });
        console.log('product event: ', product);
        useTrackEvent('add_to_cart', {
            items: [
                {
                    item_id: product.id,
                    item_name: product.name,
                    quantity: 1,
                    price: product.price,
                    currency: currency,
                }
            ],
            currency: currency,
            value: product.price,
        })
    }

    const purchaseEvent = (order: { id: Number | String, total: Number, currency: String, items: Array<Object> }) => {
        const currency = order.currency || 'ILS';
        if (typeof (adscaleEventPurchase) == 'function') {
            const adscale = adscaleEventPurchase(order.id);

        }
        $fbq('track', 'Purchase', {
            content_ids: order.items.map((item) => item.id),
            contents: order.items.map((item) => {
                return {
                    id: item.id,
                    quantity: item.quantity,
                }
            }),
            content_type: 'product',
            num_items: order.items.length,
            value: order.total,
            currency: currency,
        });

        useTrackEvent('purchase', {
            items: (order.items.map((item) => ({
                item_id: item.id,
                item_name: item.name,
                quantity: 1,
                price: item.price,
                currency: currency,
            }))),
            currency: currency,
            value: Number(order.total),
        });
    }
    const viewContentEvent = (product: { id: Number | String, price: Number, name: String, currency: String }) => {
        const currency = product?.currency || "ILS";
        if (typeof (adscaleViewContent) == 'function') {
            const adscale = adscaleViewContent(product.id);

        }
        $fbq('track', 'ViewContent', {
            content_ids: [product.id],
            content_name: product.name,
            content_type: 'product',
            value: product.price,
            currency: currency,
        });
        useTrackEvent('view_content', {
            items: [
                {
                    item_id: product.id,
                    item_name: product.name,
                    quantity: 1,
                    price: product.price,
                    currency: currency,
                }
            ],
            currency: currency,
            value: product.price,
        })
    }
    return {addToCartEvent, purchaseEvent, viewContentEvent};
}