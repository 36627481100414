import { storeToRefs } from "pinia";
type CreateAccountInput = {
  userName: string;
  password: string;
};
export const useAuth = () => {
  const { sessionToken, user, customer, authToken, refreshToken, orders, viewer } = storeToRefs(useUserStore());

  const loginCookie = useCookie("sasa_auth");
  const { refreshCart, resetInitialState } = useCart();
  const { clearAllCookies, logGQLError } = useHelpers();

  const isPending = useState<boolean>("isPending", () => false);

  // Log in the user
  const loginUser = async (credentials: CreateAccountInput): Promise<{ success: boolean; error: any }> => {
    isPending.value = true;

    try {
      const { loginWithCookies } = await GqlUserLogin(credentials);

      if (loginWithCookies?.status === "SUCCESS") {
        await refreshCart();
        if (viewer === null) {
          return {
            success: false,
            error:
              "Your credentials are correct, but there was an error logging in. This is most likely due to an SSL error. Please try again later. If the problem persists, please contact support.",
          };
        }
      }

      return {
        success: true,
        error: null,
      };
    } catch (error: any) {
      logGQLError(error);

      return {
        success: false,
        error: error?.gqlErrors?.[0]?.message,
      };
    } finally {
      isPending.value = false;
    }
  };

  // Log out the user
  const logoutUser = async (): Promise<{ success: boolean; error: any }> => {
    const { clearAllCookies } = useHelpers();
    isPending.value = true;
    try {
      const { logout } = await GqlUserLogout();
      if (logout) {
        isPending.value = false;
        await refreshCart();
        clearAllCookies();
        viewer.value = null;
        customer.value = { billing: {}, shipping: {} };
      }
      return { success: true, error: null };
    } catch (error) {
      isPending.value = false;
      return { success: false, error };
    }
  };

  // Register the user
  const registerUser = async (userInfo: any): Promise<{ success: boolean; error: any }> => {
    isPending.value = true;
    try {
      const { registerCustomer } = await GqlRegisterCustomer({ input: userInfo });
      return { success: true, error: null };
    } catch (error: any) {
      logGQLError(error);
      const gqlError = error?.gqlErrors?.[0];
      isPending.value = false;
      return { success: false, error: gqlError?.message };
    }
  };

  // Update the user state
  const updateCustomer = (payload: any): void => {
    const sessionToken = payload?.sessionToken;
    if (sessionToken) {
      useGqlHeaders({ "woocommerce-session": `Session ${sessionToken}` }, "actions");
      const newToken = useCookie("woocommerce-session");
      newToken.value = sessionToken;
    }
    customer.value = payload;
    isPending.value = false;
  };

  const getOrders = async (): Promise<{ success: boolean; error: any }> => {
    try {
      const { customer } = await GqlGetOrders();
      if (customer) {
        orders.value = customer.orders?.nodes ?? [];
        return { success: true, error: null };
      }
      return { success: false, error: "There was an error getting your orders. Please try again later." };
    } catch (error: any) {
      logGQLError(error);
      const gqlError = error?.gqlErrors?.[0];
      return { success: false, error: gqlError?.message };
    }
  };

  const updateViewer = (payload: any): void => {
    viewer.value = payload;
    isPending.value = false;
  };

  return {
    viewer,
    customer,
    loginUser,
    logoutUser,
    updateCustomer,
    updateViewer,
    isPending,
    registerUser,
  };
};
