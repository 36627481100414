export function useProductHelpers() {
  function parseCategoriesSkus(skus: Object) {
    const categories = [];
    for (const key in skus) {
      const product = skus[key];
      if (product && !_IsUndefined(product?.productCategories?.nodes[0])) {
        categories.push({ uri: product?.uri, category: product?.productCategories?.nodes[0] });
      }
      if (!_IsEmpty(product?.nodes)) {
        categories.push({
          uri: product?.nodes[0].uri,
          category: product.nodes[0]?.productCategories?.nodes[0],
        });
      }
    }
    return categories.sort((a, b) => a?.cateogry?.menuOrder - b?.category?.menuOrder);
  }

  function parseCartProduct(product: Object) {
    const skus = {
      "front-set": "01",
      row1: "04",
      row3: "03",
      trunk1: "02",
      trunk2: "02",
    };
    let newSku = product?.product?.sku;
    const isVarProduct = product.product?.productFields?.isVarProduct;
    if (!_IsUndefined(product?.rawData?.selected_options?.value)) {
      newSku += "-" + skus[product?.rawData?.selected_options?.value];
    }
    if (isVarProduct) {
      const selectedColor = product.rawData?.mat_color.value;
      const found = _Find(product.product.productFields.varProduct.variations, { nameEn: selectedColor });
      newSku = found?.sku;
    }

    let cartProduct = {
      id: product?.product?.databaseId,
      sku: newSku,
      product: {
        name: product.product?.name,
        makes: product?.product?.makes?.nodes,
        productFields: product?.product?.productFields,
        productCategories: product?.product?.productCategories,
        years: product?.product?.years?.nodes,
      },
      price: Number(product?.price),
      fullPrice: Number(product?.fullPrice) || null,
      uri: product?.product?.uri,
      key: "",
      quantity: product?.quantity,
      data: product?.rawData,
    };
    if (product.product.makes && product.product.makes.nodes.length > 0) {
      cartProduct.model = product.product.makes.nodes[0].name;
      cartProduct.make = product.product.makes.nodes[0]?.parent?.node.name;
    }

    return cartProduct;
  }

  return {
    parseCartProduct,
    parseCategoriesSkus,
  };
}
