const url = "https://admin.sasa.co.il/wp-json/wp/v2/";
const menusUrl = "https://admin.sasa.co.il/wp-json/menus/v1/menus/";
const filterUrl = "https://admin.sasa.co.il/wp-json/wp/v2/cars-filter/";
const wcUrl = "https://admin.sasa.co.il/wp-json/wc/v3/";
const consumerKey = "ck_2ce44bf397b6ff454dbad1bf14aa5e29fd634dff";
const consumerSecret = "cs_2b38dc6a0c54960d15b32e35b979805d60c9b1d8";
const contactFormUrl = "https://admin.sasa.co.il/wp-json/contact-form-7/v1/contact-forms/";

async function wpFetch(endpoint = "", inputParams = {}) {
    const {data} = await useFetch(url + endpoint, {params: inputParams});

    return data.value;
}

async function wpSendForm(contactFormId: string = '', formData = {}) {

    const {data} = await useFetch(`${contactFormUrl}${contactFormId}/feedback`, {
        method: 'POST',
        // headers: {"Content-Type": 'multipart/form-data'},
        body: formData

    });
    return data.value;
}

async function wpMenusFetch(endpoint = "") {
    const {data} = await useFetch(menusUrl + endpoint);

    return data.value;
}

async function wcFetch(endpoint = "", inputParams = {}) {
    const {data} = await useFetch(wcUrl + endpoint, {
        params: inputParams,
        headers: {
            Authorization: `Basic ${consumerKey + ":" + consumerSecret}`,
        },
    });

    return data;
}

export {wpFetch, wpMenusFetch, wcFetch, wpSendForm};
