import payload_plugin_qLmFnukI99 from "/vercel/path0/node_modules/@pinia/nuxt/dist/runtime/payload-plugin.js";
import revive_payload_client_4sVQNw7RlN from "/vercel/path0/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import vuetify_icons_RMzWu406ID from "/vercel/path0/node_modules/vuetify-nuxt-module/dist/runtime/plugins/vuetify-icons.mjs";
import unhead_KgADcZ0jPj from "/vercel/path0/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/vercel/path0/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_MwZUzHrRNP from "/vercel/path0/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_yVLowv6hDl from "/vercel/path0/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/vercel/path0/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/vercel/path0/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/vercel/path0/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_YdLad5Mpq3 from "/vercel/path0/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/vercel/path0/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/vercel/path0/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_client_xSLIPjreZL from "/vercel/path0/node_modules/nuxt-meta-pixel/dist/runtime/plugin.client.mjs";
import plugin_client_UYiXMU8ZyN from "/vercel/path0/node_modules/nuxt-gtag/dist/runtime/plugin.client.js";
import plugin_gab9g3XuEx from "/vercel/path0/node_modules/nuxt-aos/dist/runtime/plugin.js";
import plugin_UC48QI1yOB from "/vercel/path0/node_modules/@hypernym/nuxt-anime/dist/runtime/plugin.mjs";
import plugin_U4BUIcs89R from "/vercel/path0/node_modules/nuxt-graphql-client/dist/runtime/plugin.js";
import plugin_PSbK9A48ZQ from "/vercel/path0/node_modules/pinia-plugin-persistedstate/dist/nuxt/runtime/plugin.js";
import vuetify_no_client_hints_wMKVLl9fyU from "/vercel/path0/node_modules/vuetify-nuxt-module/dist/runtime/plugins/vuetify-no-client-hints.mjs";
import directives_8CcCirWtnE from "/vercel/path0/plugins/directives.ts";
import iframeListener_XScyt4Pm4A from "/vercel/path0/plugins/iframeListener.ts";
import init_grdyAU8lhf from "/vercel/path0/plugins/init.ts";
import onError_GBuFIufoYZ from "/vercel/path0/plugins/onError.ts";
import providers_cGZ9Usn9co from "/vercel/path0/plugins/providers.ts";
import vuetify_nuxt_plugin_client_JOtFoHdxcp from "/vercel/path0/.nuxt/vuetify-nuxt-plugin.client.mjs";
export default [
  payload_plugin_qLmFnukI99,
  revive_payload_client_4sVQNw7RlN,
  vuetify_icons_RMzWu406ID,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  _0_siteConfig_MwZUzHrRNP,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  plugin_vue3_YdLad5Mpq3,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_client_xSLIPjreZL,
  plugin_client_UYiXMU8ZyN,
  plugin_gab9g3XuEx,
  plugin_UC48QI1yOB,
  plugin_U4BUIcs89R,
  plugin_PSbK9A48ZQ,
  vuetify_no_client_hints_wMKVLl9fyU,
  directives_8CcCirWtnE,
  iframeListener_XScyt4Pm4A,
  init_grdyAU8lhf,
  onError_GBuFIufoYZ,
  providers_cGZ9Usn9co,
  vuetify_nuxt_plugin_client_JOtFoHdxcp
]