import {ThemeGeneralSettings, ThemeGeneralSettings_Globaloptions} from "~/types/objects";


export const useGlobalStore = defineStore("GlobalStore", {
  state: () => ({
    currentPage: null as any,
    menus: [] as any,
    options: {} as ThemeGeneralSettings_Globaloptions,
    carFilter: [] as any,
    carsFilter3d: [] as any,
    filterPop: false as boolean,
    filterPopAutoSubmit: false as boolean,
    productsAttributes: [] as any,
    queriedProducts: [] as any,
    removedFromQuery: [] as any,
    initSelectedProduct: false as boolean,
    selectedMeta: [] as any,
    filteredProducts: [] as any,
    madeInIsraelTag: {},
    outOfStock: [] as Array<number>,
    firstTimeOpened: false as boolean,
    selectedProduct: {
      make: null,
      year: null,
      model: null,
      meta: {},
    } as any,
  }),
  getters: {
    globalOptions: (state: { options: any }) => {
      return state.options || null;
    },
    currentPageFields: (state: { currentPage: { template: any } }) => {
      return state.currentPage ? state.currentPage.template : null;
    },
    matSelectUri: (state: { options: any }) => {
      return state.options?.globalMatPage?.nodes[0].uri || null;
    },
    queriedProductsAttributes: (state: { queriedProducts: any }) => {
      let attributes = {} as any;
      const products = state.queriedProducts;
      if (_IsArray(products) && products.length == 1) return null;
      for (const index in products) {
        const p_attributes = products[index].globalAttributes?.nodes || null;
        if (!p_attributes) continue;
        for (const key in p_attributes) {
          //const att = p_attributes[key].terms.nodes[0];
          const all_att = p_attributes[key].terms.nodes;
          all_att.forEach((att: any) => {
            if (_IsUndefined(attributes[att.taxonomyName])) {
              attributes[att.taxonomyName] = {
                name: att.taxonomyName,
                parsedName: _Replace(att.taxonomyName, "pa_", ""),
                values: [],
              };
            }
            attributes[att.taxonomyName].values.push({
              image: att.attributesImages?.image || null,
              name: att.name,
              sku: products[index].sku,
              slug: att.slug,
            });
          });
        }
      }
      for (const key in attributes) {
        attributes[key].values = _UnionBy(attributes[key].values, "slug");
      }
      return attributes;
    },
    madeInIsraelProducts: (state: { madeInIsraelTag: any }) => {
      const products = state.madeInIsraelTag?.products?.nodes || null;
      if (products) {
        return products?.map((product: any) => product.databaseId) || [];
      }
      return [];
    },
  },
  actions: {
    setCarFilterPopup(flag: boolean) {
      this.filterPop = flag;
    },
    setStock(stock: Array<number>) {
      this.outOfStock = stock;
    },
    setMadeInIsraelTag(tag: any) {
      this.madeInIsraelTag = tag;
    },
    goBackStep() {
      //this.queriedProducts = [...this.queriedProducts, ...this.removedFromQuery];
      //this.removedFromQuery = [];
      const tmpProducts = [];
      const skusToRemove = [];
      const meta = this.selectedMeta.pop();
      const removed = _Remove(this.selectedMeta, (row) => meta == row);
      delete this.selectedProduct.meta[meta];
      this.removedFromQuery.forEach((product: any) => {
        const p_attributes = product.globalAttributes?.nodes || null;
        for (const key in p_attributes) {
          const att = p_attributes[key].terms.nodes;
          for (const index in att) {
            if (_IsEqual(att[index].taxonomyName, meta)) {
              tmpProducts.push(product);
              skusToRemove.push(product.sku);
            }
          }
        }
      });

      skusToRemove.forEach((sku: any) => {
        _Remove(this.removedFromQuery, (row) => sku == row.sku);
      });
      this.queriedProducts = [...this.queriedProducts, ...tmpProducts];
    },
    removeProductFromQuery(taxonomy: string, slug: string) {
      if (this.initSelectedProduct) {
        this.selectedProduct.meta = {};
        this.initSelectedProduct = false;
      }

      const products = this.queriedProducts.filter((product: any) => {
        const p_attributes = product.globalAttributes?.nodes || null;
        for (const key in p_attributes) {
          const att = p_attributes[key].terms.nodes;
          for (const index in att) {
            if (_IsEqual(att[index].taxonomyName, taxonomy) && _IsEqual(att[index].slug, slug)) {
              if (_IsEmpty(this.selectedProduct.meta[att[index].taxonomyName])) {
                this.selectedProduct.meta[att[index].taxonomyName] = {
                  taxonomy: att[index].taxonomyName,
                  value: att[index].name,
                  label: p_attributes[key].label,
                };
              }
              return true;
            }
          }
        }
      });
      this.removedFromQuery = [...this.removedFromQuery, ..._DifferenceBy(this.queriedProducts, products, "sku")];
      this.removedFromQuery = _UniqBy(this.removedFromQuery, "sku");
      this.selectedMeta.push(taxonomy);
      this.setQueriedProducts(products);
      setTimeout(() => {}, 200);
    },
    selectProductYear(sku: string) {
      const products = this.queriedProducts.filter((product: any) => {
        return _IsEqual(product.sku, sku);
      });
      this.removedFromQuery = [...this.removedFromQuery, ..._DifferenceBy(this.queriedProducts, products, "sku")];
      this.removedFromQuery = _UniqBy(this.removedFromQuery, "sku");

      this.setQueriedProducts(products);
    },
    setGlobalOptions(options: any) {
      this.options = options;
    },
    setMenus(menus: any) {
      this.menus = menus;
    },
    setCurrentPage(page: any) {
      if (_IsArray(page)) {
        this.currentPage = page[0];
      } else {
        this.currentPage = page;
      }
      //useSeoMeta(this.currentPage.yoast_head_json);
    },
    setQueriedProducts(products: any) {
      this.queriedProducts = products;
    },
    setSelectedProduct(product: any) {},
    setCarFilter(filter: any) {
      this.carFilter = filter;
    },
    setCarFilter3d(filter: any) {
      this.carsFilter3d = filter;
    },
    setFilteredProducts(products: any) {
      this.filteredProducts = products;
    },
    setProductsAttributes(attributes: any) {
      this.productsAttributes = attributes;
    },
    addFieldsToGlobalOptions(options: any) {
      this.options = { ...this.options, ...options };
    },
    selectedProductZero() {
      this.selectedProduct = {
        make: null,
        year: null,
        model: null,
        meta: {},
      }
    }
  },
  persist: [
    {
      pick: ["selectedProduct"],
      storage: piniaPluginPersistedstate.cookies(),
    },
    {
      pick: [
        "menus",
        "options",
        "carFilter",
        "carsFilter3d",
        "filterPop",
        "filterPopAutoSubmit",
        "productsAttributes",
        "queriedProducts",
        "removedFromQuery",
        "initSelectedProduct",
        "selectedMeta",
        "filteredProducts",
      ],
      storage: piniaPluginPersistedstate.localStorage(),
    },
  ],
});
