import { useGql } from '#imports'
import { getSdk as defaultGqlSdk } from '#gql/default'
import { getSdk as actionsGqlSdk } from '#gql/actions'
import { getSdk as getGqlSdk } from '#gql/get'
export const GqlSdks = {
  default: defaultGqlSdk,
  actions: actionsGqlSdk,
  get: getGqlSdk,
}
export const GqClientOps = {"default":["CartFragment","CustomerFragment","SimpleProduct","VariableProduct","imageFragment","homeFieldsFragment","contactPageFragment","faqPageFragment","galleryPageTemplate","MatCategoryFragment","aboutFieldsFragment","matSelectFieldsFragment","getGalleriesByMake","getGalleries","getOrder","getCar","getMatSvg","getCarBySku","getProductByUri","getMakeById","getProducts","getProductCategory","getMakes","getGlobals","getMenus","getMadeInIsrael","getPage","getHomePage","getProductCategories"],"actions":["CartFragmentAction","CartItem","CustomerFragmentAction","SimpleProductAction","imageFragmentAction","addToCart","addToCartItems","applyCoupon","ChangeShippingMethod","Checkout","EmptyCart","registerCustomer","removeCoupons","UpDateCartQuantity","BulkUpdateQuantities","RemoveItems","updateCustomer","userLogin","userRegister","userLogout","getSession","getOrders","getCart"],"get":["MatCategoryFragmentGet","MenuItem","SimpleProductGet","VariableProductGet","aboutFieldsFragmentGet","homeFieldsFragmentGet","contactPageFragmentGet","faqPageFragmentGet","galleryPageTemplateGet","imageFragmentGet","matSelectFieldsFragmentGet","getProductByUriGet","getCategories"]}
export const GqlBulkUpdateQuantities = (...params) => useGql()('BulkUpdateQuantities', ...params)
export const GqlChangeShippingMethod = (...params) => useGql()('ChangeShippingMethod', ...params)
export const GqlCheckout = (...params) => useGql()('Checkout', ...params)
export const GqlEmptyCart = (...params) => useGql()('EmptyCart', ...params)
export const GqlRemoveItems = (...params) => useGql()('RemoveItems', ...params)
export const GqlUpDateCartQuantity = (...params) => useGql()('UpDateCartQuantity', ...params)
export const GqlAddToCart = (...params) => useGql()('addToCart', ...params)
export const GqlAddToCartItems = (...params) => useGql()('addToCartItems', ...params)
export const GqlApplyCoupon = (...params) => useGql()('applyCoupon', ...params)
export const GqlGetCart = (...params) => useGql()('getCart', ...params)
export const GqlGetOrders = (...params) => useGql()('getOrders', ...params)
export const GqlGetSession = (...params) => useGql()('getSession', ...params)
export const GqlRegisterCustomer = (...params) => useGql()('registerCustomer', ...params)
export const GqlRemoveCoupons = (...params) => useGql()('removeCoupons', ...params)
export const GqlUpdateCustomer = (...params) => useGql()('updateCustomer', ...params)
export const GqlUserLogin = (...params) => useGql()('userLogin', ...params)
export const GqlUserLogout = (...params) => useGql()('userLogout', ...params)
export const GqlUserRegister = (...params) => useGql()('userRegister', ...params)
export const GqlGetCategories = (...params) => useGql()('getCategories', ...params)
export const GqlGetProductByUriGet = (...params) => useGql()('getProductByUriGet', ...params)
export const GqlGetCar = (...params) => useGql()('getCar', ...params)
export const GqlGetCarBySku = (...params) => useGql()('getCarBySku', ...params)
export const GqlGetGalleries = (...params) => useGql()('getGalleries', ...params)
export const GqlGetGalleriesByMake = (...params) => useGql()('getGalleriesByMake', ...params)
export const GqlGetGlobals = (...params) => useGql()('getGlobals', ...params)
export const GqlGetHomePage = (...params) => useGql()('getHomePage', ...params)
export const GqlGetMadeInIsrael = (...params) => useGql()('getMadeInIsrael', ...params)
export const GqlGetMakeById = (...params) => useGql()('getMakeById', ...params)
export const GqlGetMakes = (...params) => useGql()('getMakes', ...params)
export const GqlGetMatSvg = (...params) => useGql()('getMatSvg', ...params)
export const GqlGetMenus = (...params) => useGql()('getMenus', ...params)
export const GqlGetOrder = (...params) => useGql()('getOrder', ...params)
export const GqlGetPage = (...params) => useGql()('getPage', ...params)
export const GqlGetProductByUri = (...params) => useGql()('getProductByUri', ...params)
export const GqlGetProductCategories = (...params) => useGql()('getProductCategories', ...params)
export const GqlGetProductCategory = (...params) => useGql()('getProductCategory', ...params)
export const GqlGetProducts = (...params) => useGql()('getProducts', ...params)