export const useUserStore = defineStore(
  "UserStore",
  {
    state: () => ({
      user: {},
      viewer: null,
      authToken: null,
      refreshToken: null,
      customer: { billing: {}, shipping: {} },
      sessionToken: null,
      isLoggedIn: false,
      isRegistered: false,
      isGuest: false,
      isFetching: false,
      isUpdating: false,
      isLoggingIn: false,
      isLoggingOut: false,
      isRegistering: false,
      isResettingPassword: false,
      orders: [],
    }),
    getters: {
      isUserLoggedIn: (state) => {
        return state.isLoggedIn;
      },
    },
    actions: {
      setUser(user: any) {
        this.user = user;
      },
      async login({ email, password }) {
        this.isLoggingIn = true;
        const data = await GqlUserLogin(email, password);
        this.isLoggingIn = false;
        if (data?.login?.authToken) {
          this.token = data.login.authToken;
          this.isLoggedIn = true;
          this.user = data.login.user;
          return true;
        }
        return false;
      },
      async logout() {
        this.isLoggingOut = true;
        const data = await GqlLogout();
        this.isLoggingOut = false;
        if (data?.logout?.authToken) {
          this.token = null;
          this.isLoggedIn = false;
          this.user = null;
          return true;
        }
        return false;
      },
    },
  },
  {
    persist: true,
  }
);
