import { storeToRefs } from "pinia";
import { useAuth } from "@/composables/useAuth"; // Ensure this import is correct

export function useCart() {
  const { cart, isShowingCart, isUpdatingCart, isUpdatingCoupon, paymentGateways, showCart } = storeToRefs(
    useCartStore()
  );
  const { sessionToken, user, customer, authToken, refreshToken } = storeToRefs(useUserStore());
  const { clearAllCookies, clearAllLocalStorage, logGQLError } = useHelpers();
  const { setCart } = useCartStore();

  async function refreshCart(): Promise<boolean> {
    try {
      const { cart, customer, viewer, paymentGateways } = await GqlGetCart();
      const { updateCustomer, updateViewer } = useAuth();

      if (cart) updateCart(cart);
      if (customer) updateCustomer(customer);
      if (viewer) updateViewer(viewer);
      if (paymentGateways) updatePaymentGateways(paymentGateways?.nodes);

      return true; // Cart was successfully refreshed
    } catch (error: any) {
      logGQLError(error);
      clearAllCookies();
      resetInitialState();

      return false;
    }
  }

  function resetInitialState() {
    cart.value = null;
    paymentGateways.value = [];
  }
  function updateCart(payload: any): void {
    cart.value = payload;
  }

  function updatePaymentGateways(payload: PaymentGateway[]): void {
    paymentGateways.value = payload;
  }

  // toggle the cart visibility
  function toggleCart(state: boolean | undefined = undefined): void {
    state === undefined ? (isShowingCart.value = !isShowingCart.value) : (isShowingCart.value = state);
  }

  // add an item to the cart
  async function addToCart(input: AddToCartInput) {
    isUpdatingCart.value = true;
    const { addToCart } = await GqlAddToCart({ input });
    cart.value = addToCart?.cart || null;
  }

  // remove an item from the cart
  async function removeItem(key: string) {
    isUpdatingCart.value = true;

    _Remove(cart.value?.contents?.nodes, (item) => {
      if (item?.key) {
        return item?.key === key;
      }
    });

    const { removeItemsFromCart } = await GqlRemoveItems({ key: [key] });

    cart.value = removeItemsFromCart?.cart || null;
  }

  // update the quantity of an item in the cart
  async function updateItemQuantity(key: string, quantity: number) {
    isUpdatingCart.value = true;
    const { updateItemQuantities } = await GqlUpDateCartQuantity({ key, quantity });
    cart.value = updateItemQuantities?.cart || null;
    return quantity;
  }

  // empty the cart
  async function emptyCart(): Promise<void> {
    const { emptyCart } = await GqlEmptyCart();
    cart.value = emptyCart?.cart || null;
  }

  // Update shipping method
  async function updateShippingMethod(shippingMethods: string) {
    isUpdatingCart.value = true;
    const { updateShippingMethod } = await GqlChangeShippingMethod({ shippingMethods });
    cart.value = updateShippingMethod?.cart || null;
  }

  // Apply coupon
  async function applyCoupon(code: string) {
    try {
      isUpdatingCoupon.value = true;
      const { applyCoupon } = await GqlApplyCoupon({ code });
      cart.value = applyCoupon?.cart || null;
      isUpdatingCoupon.value = false;
      return { message: null };
    } catch (error: any) {
      isUpdatingCoupon.value = false;
      const gqlErrors = error?.gqlErrors;
      if (gqlErrors) {
        const message = gqlErrors[0]?.message;
        if (message) {
          return { message };
        }
      }
    }
  }

  // Remove coupon
  async function removeCoupon(code: string) {
    isUpdatingCart.value = true;
    try {
      const { removeCoupons } = await GqlRemoveCoupons({ codes: [code] });
      cart.value = removeCoupons?.cart || null;
    } catch (error) {
      console.error(error);
    }
    isUpdatingCart.value = false;
  }

  watch(cart, (val) => {
    isUpdatingCart.value = false;
  });

  return {
    cart,
    isShowingCart,
    isUpdatingCart,
    isUpdatingCoupon,
    refreshCart,
    toggleCart,
    addToCart,
    removeItem,
    updateItemQuantity,
    emptyCart,
    updateShippingMethod,
    applyCoupon,
    removeCoupon,
    paymentGateways,
    resetInitialState,
  };
}
